import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const useAuthorization = (firebase, condition) => {
  const [state] = useState({ firebaseStarted: false });
  useEffect(() => {
    if (firebase && !state.firebaseStarted) {
      const listener = firebase.onAuthUserListener(
        authUser => {
          if (!condition(authUser)) {
            navigate(ROUTES.SIGN_IN);
          }
        },
        () => navigate(ROUTES.SIGN_IN),
      );
      return listener;
    }
  }, [condition, firebase, state.firebaseStarted]);
}

const withAuthorization = condition => Component => {
  const WithAuthorization = props => {
    useAuthorization(props.firebase, condition);
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          condition(authUser) ? <Component {...props} /> : null
        }
      </AuthUserContext.Consumer>
    );
  }
  return withFirebase(WithAuthorization);
}

export default withAuthorization;
