import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  head: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0),
  },
}));

const Head = ({children}) => {
  const classes = useStyles();

  return (
    <Box className={classes.head}>{children}</Box>
  )
}

export default Head;