import React, { useState, useEffect } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const useAuthentication = firebase => {
  const [state, setState] = useState({ authUser: null, firebaseStarted: false });

  useEffect(() => {
    setState(state => ({
      ...state,
      authUser: JSON.parse(localStorage.getItem('authUser')),
    }));

    if (firebase && !state.firebaseStarted) {
      setState(state => ({
          ...state,
          firebaseStarted: true,
        })
      );
      const listener = firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem(
            'authUser',
            JSON.stringify(authUser),
          );
          setState(state => ({ ...state, authUser }));
        },
        () => {
          localStorage.removeItem('authUser');
          setState((state) => ({ ...state, authUser: null }));
        },
      );
      return listener;
    }
  }, [state.firebaseStarted, firebase])

  return state;
}

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const state = useAuthentication(props.firebase);

    return (
      <AuthUserContext.Provider value={state.authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  }
  return withFirebase(WithAuthentication);
}

export default withAuthentication;
