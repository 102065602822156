import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import Head from '../Form/layout/Head';
import Body from '../Form/layout/Body';
import Footer from '../Form/layout/Footer';
import ButtonWithLoading from '../Form/inputs/ButtonWithLoading';

const translations = {
  page: {
    titles: {
      verifyYourEmail: 'Verify your E-Mail',
      emailConfirmationSent: 'E-mail confirmation sent',
    },
    messages: {
      verifyYourEmail: 'Check you E-Mails (Spam folder included) for a confirmation E-Mail. Refresh this page once you confirmed your E-Mail.',
      emailConfirmationSent: 'Check you E-Mails (Spam folder included) for a confirmation E-Mail. Refresh this page once you confirmed your E-Mail.',
    },
    ui: {
      buttons: {
        sendConfirmationEmail: {
          label: 'Send confirmation E-Mail'
        },
      },
    },
  },
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');


const withEmailVerification = Component => {
  const WithEmailVerification = props => {
    const [state, setState] = useState({ isSent: false });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(true);
    const classes = useStyles();
    const onSendEmailVerification = () => {
      setLoading(true);
      setSuccess(false);
      return props.firebase
        .doSendEmailVerification()
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setState({ isSent: true })
        });
    };
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          needsEmailVerification(authUser) ? (
            <div className={classes.root}>
              {state.isSent ? (
                <Fragment>
                  <Head>
                    <Typography variant="h5">
                      {translations.page.titles.emailConfirmationSent}
                    </Typography>
                  </Head>
                  <Body>
                    <p>{translations.page.messages.emailConfirmationSent}</p>
                  </Body>
                </Fragment>
              ) : (
                <Fragment>
                  <Head>
                    <Typography variant="h5">
                      {translations.page.titles.verifyYourEmail}
                    </Typography>
                  </Head>
                  <Body>
                    <p>{translations.page.messages.verifyYourEmail}</p>
                  </Body>
                </Fragment>
                )}
              <Footer>
                <ButtonWithLoading
                  type="button"
                  success={success}
                  loading={loading}
                  disabled={loading || state.isSent}
                  onClick={onSendEmailVerification}
                  label={translations.page.ui.buttons.sendConfirmationEmail.label}
                /> 
              </Footer>
            </div>
          ) : (
            <Component {...props} />
          )
        }
      </AuthUserContext.Consumer>
    );  
  }
  return withFirebase(WithEmailVerification);
}

export default withEmailVerification;
